import React, { useState, useEffect } from 'react';
import './ThankYouPage.css';
import submitingIMG from '../assets/img/submiting-images.svg';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

const ThankYouPage = () => {
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='main-thanks-parent'>
      <div className='thanks wrapper-thanks'>
        <div className='submithing-image-wrapper'>
          {showConfetti && (
            <Confetti
              width={width}
              height={height}
            />
          )}
          <img src={submitingIMG} alt='submitting' />
        </div>
        <h1>Thanks for submitting. Check Emails for Details</h1>
      </div>
    </div>
  );
}

export default ThankYouPage;