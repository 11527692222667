import React from "react";
import "./CameraComponent.css";
import errorImg from '../assets/img/error-images.svg'

const ErrorPage = () => {


    return (
        <div className="error-main-card-parent">
            <div className="wrapper-error-parent">
                <img src={errorImg} />
                <button className="submit-error-page">Back</button>
            </div>
        </div>
    );
};

export default ErrorPage;