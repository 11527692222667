// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thanks.wrapper-thanks {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif; */
  background: linear-gradient(180deg, #fff -62%, #fff 39%, #007AFF 250%);
  border-radius: 20px;
  padding: 30px;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 450px;
  margin: 14px;
}

.thanks.wrapper-thanks h1 {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  color: #333;
  text-align: center;
}

.main-thanks-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}

button.toggle-button {
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  border: none;
  height: 30px;
  width: 40px;
  border-radius: 6px;
}

.capture-icon-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/ThankYouPage.css"],"names":[],"mappings":"AAAA;EACE;;;;;;;;;;;sCAWoC;EACpC,sEAAsE;EACtE,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,wCAAwC;EACxC,aAAa;EACb,YAAY;AACd;;AAEA;EACE,SAAS;EACT,UAAU;EACV,iCAAiC;EACjC,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;AACV","sourcesContent":[".thanks.wrapper-thanks {\n  /* display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  font-size: 2rem;\n  font-weight: bold;\n  color: #333;\n  text-align: center;\n  background-color: #f4f4f4;\n  margin: 0;\n  padding: 0;\n  font-family: \"Roboto\", sans-serif; */\n  background: linear-gradient(180deg, #fff -62%, #fff 39%, #007AFF 250%);\n  border-radius: 20px;\n  padding: 30px;\n  width: 100%;\n  max-width: 350px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  height: 450px;\n  margin: 14px;\n}\n\n.thanks.wrapper-thanks h1 {\n  margin: 0;\n  padding: 0;\n  font-family: \"Roboto\", sans-serif;\n  font-size: 26px;\n  color: #333;\n  text-align: center;\n}\n\n.main-thanks-parent {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 100vh;\n  background-color: rgba(0, 0, 0, 0.6);\n}\n\nbutton.toggle-button {\n  font-size: 22px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  height: 30px;\n  width: 40px;\n  border-radius: 6px;\n}\n\n.capture-icon-parent {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
