import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./CameraComponent.css";
import { CameraInfoContext } from "../context/CameraInfoContext";

const MyIdentity = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { setEndUserId } = useContext(CameraInfoContext)

    const handleButtonClick = () => {
        setEndUserId(id)
        navigate("/uploadId");
    };

    return (
        <div className="main-card-parent">
            <div className="parent-card">
                <h2 className="verify-text">Verify your identity</h2>
                <p className="verify-discription">We need some information to help us confirm your identity.</p>
                <div className="icon-container">
                    <div className="icon-wrapper">
                        <div className="checkmark">✓</div>
                    </div>
                </div>
                <button className="document-choose-button" onClick={handleButtonClick}>Choose document type</button>
                <p className="footer-paragraph">Verifying usually takes a few seconds.</p>
            </div>
        </div>
    );
};

export default MyIdentity;
