// import MyIdentity from "./components/identity";
// import CameraComponent from "./components/CameraComponent";
// import UploadId from "./components/uploadId";
// import ErrorPage from "./components/Error";
// import DocumentPage from "./components/DocumentPage";
// import MenuOption from "./components/MenuOption";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import ThankYouPage from "./components/ThankYouPage";
// import { CameraInfoContext } from "./context/CameraInfoContext";
// import { useState } from "react";

// function App({ children }) {

//   const [selectedId, setSelectedId] = useState("");
//   const [endUserId, setEndUserId] = useState("")


//   return (
//     <Router>
//       <CameraInfoContext.Provider value={{ selectedId, setSelectedId, endUserId, setEndUserId }}>
//         <Routes>
//           <Route path='/' element={<ErrorPage />} />
//           <Route path="/:id" element={<MyIdentity />} />
//           <Route path='/uploadId' element={<UploadId />} />
//           <Route path="/camera" element={<CameraComponent />} />
//           <Route path="/document" element={<DocumentPage />} />
//           {/* <Route path="/upload-document" element={<MenuOption />} /> */}
//           <Route path='/thanks' element={<ThankYouPage />} />
//         </Routes>
//       </CameraInfoContext.Provider>
//     </Router>

//   );
// }

// export default App;
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MyIdentity from "./components/identity";
import CameraComponent from "./components/CameraComponent";
import UploadId from "./components/uploadId";
import ErrorPage from "./components/Error";
import DocumentPage from "./components/DocumentPage";
import ThankYouPage from "./components/ThankYouPage";
import { CameraInfoProvider } from "./context/CameraInfoContext";

function App() {
  return (
    <Router>
      <CameraInfoProvider>
        <Routes>
          <Route path="/" element={<ErrorPage />} />
          <Route path="/:id" element={<MyIdentity />} />
          <Route path="/uploadId" element={<UploadId />} />
          <Route path="/camera" element={<CameraComponent />} />
          <Route path="/document" element={<DocumentPage />} />
          <Route path="/thanks" element={<ThankYouPage />} />
        </Routes>
      </CameraInfoProvider>
    </Router>
  );
}

export default App;