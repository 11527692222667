// import { createContext } from 'react';

// export const CameraInfoContext = createContext("");
import React, { createContext, useState } from "react";
import { HiWallet } from "react-icons/hi2";

export const CameraInfoContext = createContext();

export const CameraInfoProvider = ({ children }) => {
    const [selectedId, setSelectedId] = useState("");
    const [endUserId, setEndUserId] = useState("");
    const [pdfFile, setPdfFile] = useState(null);

    return (
        <CameraInfoContext.Provider
            value={{
                selectedId,
                setSelectedId,
                endUserId,
                setEndUserId,
                pdfFile,
                setPdfFile
            }}
        >
            {children}
        </CameraInfoContext.Provider>
    );
};

// import React, { createContext, useState } from 'react';

// export const CameraInfoContext = createContext();

// export const CameraInfoProvider = ({ children }) => {
//     const [pdfFile, setPdfFile] = useState(null);

//     return (
//         <CameraInfoContext.Provider value={{ pdfFile, setPdfFile }}>
//             {children}
//         </CameraInfoContext.Provider>
//     );
// };

