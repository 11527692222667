import React, { useState, useRef, useContext } from "react";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import "./CameraComponent.css";
import { FaCameraRotate } from "react-icons/fa6";
import { MdCameraEnhance } from "react-icons/md";
import toast, { Toaster } from 'react-hot-toast';
import { CameraInfoContext } from "../context/CameraInfoContext";

const CameraComponent = () => {
  const [frontPhoto, setFrontPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);
  const [selfiePhoto, setSelfiePhoto] = useState(null);
  const [currentCapture, setCurrentCapture] = useState("Front Photo");
  const [facingMode, setFacingMode] = useState("user");
  const [lastClosedPhoto, setLastClosedPhoto] = useState(null);
  const [isCapturing, setIsCapturing] = useState(true);

  const { selectedId, endUserId, pdfFile } = useContext(CameraInfoContext);

  const webcamRef = useRef(null);
  const navigate = useNavigate();

  const closeFrontPhoto = () => {
    setFrontPhoto(null);
    setLastClosedPhoto("Front Photo");
    setIsCapturing(false);
  };

  const closeBackPhoto = () => {
    setBackPhoto(null);
    setLastClosedPhoto("Back Photo");
    setIsCapturing(false);
  };

  const closeSelfiePhoto = () => {
    setSelfiePhoto(null);
    setLastClosedPhoto("Selfie Photo");
    setIsCapturing(false);
  };

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (!frontPhoto) {
      setFrontPhoto(imageSrc);
      setCurrentCapture("Back Photo");
    } else if (!backPhoto) {
      setBackPhoto(imageSrc);
      setCurrentCapture("Selfie Photo");
    } else {
      setSelfiePhoto(imageSrc);
      setCurrentCapture("Complete");
    }
    setIsCapturing(true);
    setLastClosedPhoto(null);
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const sendPhotosToAPI = async () => {
    if (frontPhoto && backPhoto && selfiePhoto && pdfFile) {
      const formData = new FormData();

      const frontBlob = base64ToBlob(frontPhoto.split(",")[1], 'image/png');
      const backBlob = base64ToBlob(backPhoto.split(",")[1], 'image/png');
      const selfieBlob = base64ToBlob(selfiePhoto.split(",")[1], 'image/png');
      const pdfBlob = base64ToBlob(pdfFile.split(",")[1], 'application/pdf');

      formData.append("front", frontBlob, "front.png");
      formData.append("back", backBlob, "back.png");
      formData.append("selfie", selfieBlob, "selfie.png");
      formData.append("id", endUserId);
      formData.append("type", "id_card");
      formData.append("pdf", pdfBlob, "document.pdf");

      const response = await fetch(
        "https://ballerine-api.foodrabbit.store/api/v1/manage-kyc-pdf",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        navigate("/thanks");
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    } else {
      return Promise.reject("Please capture all three photos and upload a PDF before sending");
    }
  };

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const handleSubmit = () => {
    toast.promise(
      sendPhotosToAPI(),
      {
        loading: 'Submitting...',
        success: 'Photos and PDF successfully submitted',
        error: 'Error occurred',
      }
    );
  };

  return (
    <div className='camera-container'>
      <div className="wrapper-camera-container">
        <div className='webcam-container'>
          <h1>{isCapturing ? currentCapture : ` ${lastClosedPhoto}`}</h1>
          <Webcam
            audio={false}
            height={360}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            width={480}
            videoConstraints={{ facingMode }}
          />
          <div className='capture-icon-parent'>
            <button
              onClick={capture}
              className='capture-button common-capture-btn'
            >
              <MdCameraEnhance />
            </button>
            <button
              onClick={toggleFacingMode}
              className='toggle-button common-capture-btn'
            >
              <FaCameraRotate />
            </button>
          </div>
        </div>
        <div className='photo-preview'>
          {frontPhoto && (
            <div className='front-parent'>
              <div className="front-camera-close" onClick={closeFrontPhoto}>X</div>
              <h3>Front Photo</h3>
              <img src={frontPhoto} alt='Front Photo' />
            </div>
          )}
          {backPhoto && (
            <div className='front-parent'>
              <div className="front-camera-close" onClick={closeBackPhoto}>X</div>
              <h3>Back Photo</h3>
              <img src={backPhoto} alt='Back Photo' />
            </div>
          )}
          {selfiePhoto && (
            <div className='front-parent'>
              <div className="front-camera-close" onClick={closeSelfiePhoto}>X</div>
              <h3>Selfie Photo</h3>
              <img src={selfiePhoto} alt='Selfie Photo' />
            </div>
          )}
        </div>
        <div className="wrapper-submit-button-parent">
          <button
            onClick={handleSubmit}
            disabled={!frontPhoto || !backPhoto || !selfiePhoto || !pdfFile}
            className='send-button'
          >
            Submit
          </button>
          <Toaster />
        </div>
      </div>
    </div>
  );
};

export default CameraComponent;