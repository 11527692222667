// import React, { useContext, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { useDropzone } from "react-dropzone";
// import "./CameraComponent.css";
// import { CameraInfoContext } from "../context/CameraInfoContext";

// const formatFileSize = (bytes) => {
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
//     if (bytes === 0) return "0 Byte";
//     const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
// };

// const DocumentPage = () => {
//     const navigate = useNavigate();
//     const [selectedPdf, setSelectedPdf] = useState(null);

//     const onDrop = (acceptedFiles) => {
//         const isPdfFile = (file) => file.type === "application/pdf";

//         const pdfFiles = acceptedFiles.filter(isPdfFile);

//         if (pdfFiles.length > 0) {
//             setSelectedPdf(pdfFiles[0]);
//         }
//     };

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: { "application/pdf": [] },
//         maxFiles: 1,
//     });

//     const getDocument = (type) => {
//         if (type === "image") {
//             navigate("/camera");
//         } else if (type === "document") {
//             navigate("/document");
//         } else {
//             navigate("/");
//         }
//     };

//     return (
//         <div className="document-page document-parent">
//             <div className="wrapper-document upload-container">
//                 <h2>Upload your pdf document</h2>
//                 <div className="upload-area" {...getRootProps()}>
//                     <input {...getInputProps()} />
//                     <div className="upload-icon">
//                         <i className="fas fa-upload"></i>
//                     </div>
//                     <p className="upload-text">Drag and drop your PDF file here</p>
//                 </div>
//                 {selectedPdf && (
//                     <div className="pdf-preview">
//                         <div className="pdf-thumbnail">
//                             <img src="pdf-thumbnail.png" alt="PDF Preview" />
//                         </div>
//                         <div className="pdf-info">
//                             <p className="pdf-name">{selectedPdf.name}</p>
//                             <p className="pdf-size">{formatFileSize(selectedPdf.size)}</p>
//                         </div>
//                     </div>
//                 )}
//                 <button className="pdf-submit-button" onClick={() => getDocument('image')}>Next</button>
//             </div>
//         </div>
//     );
// };

// export default DocumentPage;
// !another
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useDropzone } from "react-dropzone";

// import "./CameraComponent.css";

// const formatFileSize = (bytes) => {
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
//     if (bytes === 0) return "0 Byte";
//     const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
// };

// const DocumentPage = () => {
//     const navigate = useNavigate();
//     const [selectedPdf, setSelectedPdf] = useState(null);

//     const onDrop = (acceptedFiles) => {
//         const isPdfFile = (file) => file.type === "application/pdf";

//         const pdfFiles = acceptedFiles.filter(isPdfFile);

//         if (pdfFiles.length > 0) {
//             setSelectedPdf(pdfFiles[0]);
//         }
//     };

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: { "application/pdf": [] },
//         maxFiles: 1,
//     });

//     const getDocument = (type) => {
//         if (type === "image") {
//             navigate("/camera");
//         } else if (type === "document") {
//             navigate("/document");
//         } else {
//             navigate("/");
//         }
//     };

//     return (
//         <div className="document-page document-parent">
//             <div className="wrapper-document upload-container">
//                 <h2>Upload your pdf document</h2>
//                 <div className="upload-area" {...getRootProps()}>
//                     <input {...getInputProps()} />
//                     <div className="upload-icon">
//                         <i className="fas fa-upload"></i>
//                     </div>
//                     <p className="upload-text">Drag and drop your PDF file here</p>
//                 </div>
//                 {selectedPdf && (
//                     <div className="pdf-preview">
//                         <div className="pdf-thumbnail">
//                             <img src="pdf-thumbnail.png" alt="PDF Preview" />
//                         </div>
//                         <div className="pdf-info">
//                             <p className="pdf-name">{selectedPdf.name}</p>
//                             <p className="pdf-size">{formatFileSize(selectedPdf.size)}</p>
//                         </div>
//                     </div>
//                 )}
//                 {selectedPdf && (
//                     <button className="pdf-submit-button" onClick={() => getDocument('image')}>
//                         Next
//                     </button>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default DocumentPage;
// !another
// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useDropzone } from "react-dropzone";
// import toast from 'react-hot-toast';

// import "./CameraComponent.css";

// const formatFileSize = (bytes) => {
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
//     if (bytes === 0) return "0 Byte";
//     const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
// };

// const DocumentPage = () => {
//     const navigate = useNavigate();
//     const [selectedPdf, setSelectedPdf] = useState(null);

//     const onDrop = (acceptedFiles) => {
//         const isPdfFile = (file) => file.type === "application/pdf";

//         const pdfFiles = acceptedFiles.filter(isPdfFile);

//         if (pdfFiles.length > 0) {
//             setSelectedPdf(pdfFiles[0]);
//         }
//     };

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: { "application/pdf": [] },
//         maxFiles: 1,
//     });

//     const handleNextClick = () => {
//         if (selectedPdf) {
//             toast.success('PDF uploaded successfully!', {
//                 duration: 3000,
//                 position: 'top-center',
//                 style: {
//                     background: '#333',
//                     color: '#fff',
//                     fontWeight: 'bold',
//                     padding: '16px',
//                     borderRadius: '3px',
//                 },
//             });
//             navigate('/camera');
//         }
//     };

//     return (
//         <div className="document-page document-parent">
//             <div className="wrapper-document upload-container">
//                 <h2>Upload your pdf document</h2>
//                 <div className="upload-area" {...getRootProps()}>
//                     <input {...getInputProps()} />
//                     <div className="upload-icon">
//                         <i className="fas fa-upload"></i>
//                     </div>
//                     <p className="upload-text">Drag and drop your PDF file here</p>
//                 </div>
//                 {selectedPdf && (
//                     <div className="pdf-preview">
//                         <div className="pdf-thumbnail">
//                             <img src="pdf-thumbnail.png" alt="PDF Preview" />
//                         </div>
//                         <div className="pdf-info">
//                             <p className="pdf-name">{selectedPdf.name}</p>
//                             <p className="pdf-size">{formatFileSize(selectedPdf.size)}</p>
//                         </div>
//                     </div>
//                 )}
//                 {selectedPdf && (
//                     <button className="pdf-submit-button" onClick={handleNextClick}>
//                         Next
//                     </button>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default DocumentPage;
// ! another
// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useDropzone } from "react-dropzone";
// import toast from 'react-hot-toast';
// import { CameraInfoContext } from "../context/CameraInfoContext";
// import "./CameraComponent.css";

// const formatFileSize = (bytes) => {
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
//     if (bytes === 0) return "0 Byte";
//     const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
// };

// const DocumentPage = () => {
//     const navigate = useNavigate();
//     const [selectedPdf, setSelectedPdf] = useState(null);
//     const { setPdfFile } = useContext(CameraInfoContext);

//     const [base64File, setBase64File] = useState('');


//     const toBase64 = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onload = () => resolve(reader.result);
//             reader.onerror = (error) => reject(error);
//         });
//     };

//     const onDrop = async (acceptedFiles) => {
//         // const isPdfFile = (file) => file.type === "application/pdf";

//         // const pdfFiles = acceptedFiles.filter(isPdfFile);

//         // console.log("Accpet Files : ", acceptedFiles)

//         // const base64File = toBase64(acceptedFiles);
//         // console.log(base64File);

//         // if (pdfFiles.length > 0) {
//         //     setSelectedPdf(pdfFiles[0]);
//         //     setPdfFile(pdfFiles[0]);
//         // }

//         const isPdfFile = (file) => file.type === "application/pdf";
//         const pdfFiles = acceptedFiles.filter(isPdfFile);
//         console.log("Accepted Files:", acceptedFiles);
//         if (pdfFiles.length > 0) {
//             const base64File = await toBase64(pdfFiles[0]);
//             console.log(base64File);
//             setSelectedPdf(pdfFiles[0]);
//             setPdfFile(pdfFiles[0]);
//         } else {
//             console.error("No PDF files found.");
//         }
//     };

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: { "application/pdf": [] },
//         maxFiles: 1,
//     });

//     const handleNextClick = () => {
//         if (selectedPdf) {
//             toast.success('PDF uploaded successfully!', {
//                 duration: 3000,
//                 position: 'top-center',
//                 style: {
//                     background: '#333',
//                     color: '#fff',
//                     fontWeight: 'bold',
//                     padding: '16px',
//                     borderRadius: '3px',
//                 },
//             });
//             navigate('/camera');
//         }
//     };

//     const uploadMyFile = (e) => {
//         console.log(e);
//     }

//     return (
//         <div className="document-page document-parent">
//             <div className="wrapper-document upload-container">


//                 <h2>Upload your pdf document</h2>
//                 <div className="upload-area" {...getRootProps()}>
//                     <input {...getInputProps()} />
//                     <div className="upload-icon">
//                         <i className="fas fa-upload"></i>
//                     </div>
//                     <p className="upload-text">Drag and drop your PDF file here</p>
//                 </div>
//                 {selectedPdf && (
//                     <div className="pdf-preview">
//                         <div className="pdf-thumbnail">
//                             <img src="pdf-thumbnail.png" alt="PDF Preview" />
//                         </div>
//                         <div className="pdf-info">
//                             <p className="pdf-name">{selectedPdf.name}</p>
//                             <p className="pdf-size">{formatFileSize(selectedPdf.size)}</p>
//                         </div>
//                     </div>
//                 )}
//                 {selectedPdf && (
//                     <button className="pdf-submit-button" onClick={handleNextClick}>
//                         Next
//                     </button>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default DocumentPage;


// import React, { useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useDropzone } from "react-dropzone";
// import toast from 'react-hot-toast';
// import { CameraInfoContext } from "../context/CameraInfoContext";
// import "./CameraComponent.css";

// const formatFileSize = (bytes) => {
//     const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
//     if (bytes === 0) return "0 Byte";
//     const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
// };

// const DocumentPage = () => {
//     const navigate = useNavigate();
//     const [selectedPdf, setSelectedPdf] = useState(null);
//     const { setPdfFile } = useContext(CameraInfoContext);
//     const [base64File, setBase64File] = useState('');

//     const toBase64 = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onload = () => resolve(reader.result);
//             reader.onerror = (error) => reject(error);
//         });
//     };

//     const onDrop = async (acceptedFiles) => {
//         const isPdfFile = (file) => file.type === "application/pdf";
//         const pdfFiles = acceptedFiles.filter(isPdfFile);
//         console.log("Accepted Files:", acceptedFiles);
//         if (pdfFiles.length > 0) {
//             const base64 = await toBase64(pdfFiles[0]);
//             setSelectedPdf(pdfFiles[0]);
//             setPdfFile(base64);
//             setBase64File(base64);
//         } else {
//             console.error("No PDF files found.");
//         }
//     };

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: { "application/pdf": [] },
//         maxFiles: 1,
//     });

//     const handleNextClick = async () => {
//         if (selectedPdf) {
//             toast.success('PDF uploaded successfully!', {
//                 duration: 3000,
//                 position: 'top-center',
//                 style: {
//                     background: '#333',
//                     color: '#fff',
//                     fontWeight: 'bold',
//                     padding: '16px',
//                     borderRadius: '3px',
//                 },
//             });
//             navigate('/camera');
//         }
//     };

//     return (
//         <div className="document-page document-parent">
//             <div className="wrapper-document upload-container">
//                 <h2>Upload your pdf document</h2>
//                 <div className="upload-area" {...getRootProps()}>
//                     <input {...getInputProps()} />
//                     <div className="upload-icon">
//                         <i className="fas fa-upload"></i>
//                     </div>
//                     <p className="upload-text">Drag and drop your PDF file here</p>
//                 </div>
//                 {selectedPdf && (
//                     <div className="pdf-preview">
//                         <div className="pdf-thumbnail">
//                             <img src="pdf-thumbnail.png" alt="PDF Preview" />
//                         </div>
//                         <div className="pdf-info">
//                             <p className="pdf-name">{selectedPdf.name}</p>
//                             <p className="pdf-size">{formatFileSize(selectedPdf.size)}</p>
//                         </div>
//                     </div>
//                 )}
//                 {selectedPdf && (
//                     <button className="pdf-submit-button" onClick={handleNextClick}>
//                         Next
//                     </button>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default DocumentPage;


import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import toast from 'react-hot-toast';
import { CameraInfoContext } from "../context/CameraInfoContext";
import "./CameraComponent.css";

const formatFileSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

const DocumentPage = () => {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const { setPdfFile } = useContext(CameraInfoContext);
    const [base64File, setBase64File] = useState('');
    const [preview, setPreview] = useState(null);

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const onDrop = async (acceptedFiles) => {
        const allowedTypes = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/svg",
            "image/gif",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ];

        const validFile = acceptedFiles.find(file => allowedTypes.includes(file.type));

        if (validFile) {
            const base64 = await toBase64(validFile);
            setSelectedFile(validFile);
            setPdfFile(base64);
            setBase64File(base64);
        } else {
            console.error("No valid file found.");
            toast.error('Please upload a valid file (PDF, Image, or Document).');
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': [],
            'image/*': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': []
        },
        maxFiles: 1,
    });

    useEffect(() => {
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    }, [selectedFile]);

    const handleNextClick = async () => {
        if (selectedFile) {
            toast.success('File uploaded successfully!', {
                duration: 3000,
                position: 'top-center',
                style: {
                    background: '#333',
                    color: '#fff',
                    fontWeight: 'bold',
                    padding: '16px',
                    borderRadius: '3px',
                },
            });
            navigate('/camera');
        }
    };

    const getFileIcon = (fileType) => {
        if (fileType === 'application/pdf') return 'far fa-file-pdf';
        if (fileType.includes('word')) return 'far fa-file-word';
        return 'far fa-file';
    };

    return (
        <div className="document-page document-parent">
            <div className="wrapper-document upload-container">
                <h2>Upload your document</h2>
                <div className="upload-area" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload-icon">
                        <i className="fas fa-upload"></i>
                    </div>
                    <p className="upload-text">Drag and drop your file here</p>
                </div>
                {selectedFile && (
                    <div className="file-preview">
                        <div className="file-thumbnail">
                            {preview ? (
                                <img src={preview} alt="Preview" style={{ width: '150px', height: '150px', marginTop: '15px', borderRadius: '10px', backgroundColor: "#c7c7c75e", }} />
                            ) : (
                                <i className={getFileIcon(selectedFile.type)}></i>
                            )}
                        </div>
                        <div className="file-info">
                            <p className="file-name">{selectedFile.name}</p>
                            <p className="file-size">{formatFileSize(selectedFile.size)}</p>
                        </div>
                    </div>
                )}
                {selectedFile && (
                    <button className="file-submit-button" onClick={handleNextClick}>
                        Next
                    </button>
                )}
            </div>
        </div>
    );
};

export default DocumentPage;