import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./CameraComponent.css";
import { CameraInfoContext } from "../context/CameraInfoContext";



const UploadId = () => {

    const cardType = {
        ID_CARD: 'id_card',
        DRIVING_LICENSE: 'driving_license',
        PASSPORT: 'passport',
        VOTER_ID: 'voter_id'
    }


    const { setSelectedId } = useContext(CameraInfoContext);

    const navigate = useNavigate();
    const [card, setCard] = useState(cardType.ID_CARD)
    const { idCard } = useParams();

    const handelsSeCardType = (cardType) => {
        setSelectedId(cardType)
        navigate("/upload-document")
        //navigate("/camera")
    };

    // const navigate = useNavigate()
    const getDocument = (type) => {
        if (type === "image") {
            navigate("/camera")
        } else if (type === "document") {
            navigate("/document")
        } else {
            navigate("/")
        }
    }

    return (
        <div className="main-parent">
            <div className="inner-parent-container">
                <div className="back-header">
                    <h1 className="upload-id-name">Upload ID</h1>
                </div>
                <p className="document-choose-description">Choose the document type you would like to identify with</p>
                <div className="select-option" onClick={() => getDocument('document')}>
                    {/* <div className="select-option" onClick={() => handelsSeCardType(cardType.ID_CARD)}> */}
                    <div className="select-icon">
                        <div className="wrapper-icons">🪪</div>
                    </div>
                    <div className="option-text">
                        <h2 className="option-title">ID Card</h2>
                        <p className="option-description">Government-issued personal ID.</p>
                    </div>
                </div>
                <div className="select-option" onClick={() => getDocument('document')}>
                    {/* <div className="select-option" onClick={() => handelsSeCardType(cardType.DRIVING_LICENSE)}> */}
                    <div className="select-icon">
                        <div className="wrapper-icons">🚗</div>
                    </div>
                    <div className="option-text">
                        <h2 className="option-title">Drivers license</h2>
                        <p className="option-description">Government-issued Drivers license</p>
                    </div>
                </div>
                <div className="select-option" onClick={() => getDocument('document')}>
                    {/* <div className="select-option" onClick={() => handelsSeCardType(cardType.PASSPORT)}> */}
                    <div className="select-icon">
                        <div className="wrapper-icons">🛂</div>
                    </div>
                    <div className="option-text">
                        <h2 className="option-title">Passport</h2>
                        <p className="option-description">Government-issued Passport</p>
                    </div>
                </div>

                <div className="select-option" onClick={() => getDocument('document')}>
                    {/* <div className="select-option" onClick={() => handelsSeCardType(cardType.VOTER_ID)}> */}
                    <div className="select-icon">
                        <div className="wrapper-icons">🗳️</div>
                    </div>
                    <div className="option-text">
                        <h2 className="option-title">Voter ID</h2>
                        <p className="option-description">Government-issued Voter ID</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadId;
